export enum VenuePermission {
  addAndRemoveBeers = 'venuesCanAddAndRemoveBeers',
  addAndRemoveSections = 'venuesCanAddAndRemoveSections',
  createEvent = 'venuesCanCreateEvent',
  createMenu = 'venuesCanCreateMenu',
  deleteMenu = 'venuesCanDeleteMenu',
  editBeerInfo = 'venuesCanEditBeerInfo',
  listBusinessAnalytics = 'venuesCanListBusinessAnalytics',
  listDigitalBoards = 'venuesCanListDigitalBoards',
  listHours = 'venuesCanListHours',
  listInvoices = 'venuesCanListInvoices',
  listMenuAnnouncements = 'venuesCanListMenuAnnouncements',
  listNearbyAnalytics = 'venuesCanListNearbyAnalytics',
  listPrintThemes = 'venuesCanListPrintThemes',
  listVenueAnalytics = 'venuesCanListVenueAnalytics',
  listWebsiteThemes = 'venuesCanListWebsiteThemes',
  showLocationDetails = 'venuesCanShowLocationDetails',
  showLocationInformation = 'venuesCanShowLocationInformation',
  updateMenuName = 'venuesCanUpdateMenuName',
  updateSectionName = 'venuesCanUpdateSectionName',
  manageMembers = 'membersCanManageMembers',
}

export default {}
