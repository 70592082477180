import qs from 'qs'

import {
  IItem,
  IMenu,
  ISection,
  IVenueCategories,
  IVenueDetails,
  IVenueHour,
  VenueHealth,
} from '@app/interfaces/shared'

import Base from './Base'

export type LocationSearchResponse = {
  menus: IMenu[]
  sections: ISection[]
  items: IItem[]
}

class Locations extends Base {
  static async update(id: number, params: object) {
    return await this.patch(`/locations/${id}`, params)
  }

  static async health(id: number): Promise<VenueHealth> {
    const { data } = await this.get(`/locations/${id}/health`)
    return data
  }

  static async topBeers(id: number) {
    return await this.get(`/locations/${id}/top_beers`)
  }

  static async searchAll(
    id: number,
    searchQuery: string,
  ): Promise<LocationSearchResponse> {
    const { data } = await this.get(
      `/locations/${id}/search?${qs.stringify({
        q: searchQuery,
      })}`,
    )

    return data
  }

  static async categories(id: number): Promise<IVenueCategories> {
    const { data } = await this.get(`/locations/${id}/categories`)
    return data
  }

  static async details(id: number): Promise<IVenueDetails> {
    const { data } = await this.get(`/locations/${id}/details`)
    return data.location
  }

  static async updateDetails(id: number, params: object) {
    return await this.patch(`/locations/${id}/details`, params)
  }

  static async uploadImage(id: number, image: File | null) {
    const data = new FormData()
    data.append('logo', image!)

    return this.post(`/locations/${id}/details`, data, {
      transformRequest: (data: object) => data,
    })
  }

  static async hours(id: number): Promise<IVenueHour[]> {
    const { data } = await this.get(`/locations/${id}/hours`)
    return data.hours
  }

  static async updateHours(id: number, params: object) {
    return await this.patch(`/locations/${id}/hours`, params)
  }
}

export default Locations
